<template>
  <div class="container-top">
    <div class="pc">
      <div class="bg-gray3" style="padding: 120px 0">
        <div class="container">
          <div class="intro">
            <div class="main">
              <div class="section-title">{{ intro.text.title1 }}</div>
              <div class="section-title"><span class="section-title-bold">{{ intro.text.title2 }}</span>{{
                intro.text.title3 }}
              </div>
            </div>
            <div class="margin-top-24 sub2">{{ intro.text.content1 }}</div>

            <div class="margin-top-60">
              <div class="card-wrapper">
                <div v-for="(item, idx) in intro.list" :key="'card-item-'+idx"
                     class="card-info" :class="{'bg-white': !item.isDark, 'bg-dark': item.isDark}">
                  <div class="h6" :class="{'main': !item.isDark}">{{ item.name }}</div>
                  <div class="body2 margin-top-8" :class="{'sub2': !item.isDark}">{{ item.content }}</div>
                  <div class="margin-top-20 flex-between"
                       style="align-items: flex-start">
                    <div class="unselect flex-align body2-medium"
                         :class="{'main': !item.isDark}" @click="clickItem(item.id)">
                      <div>자세히 보기</div>
                      <img :src="icon('draw_arrow-right')" class="margin-left-12 svg-sub3">
                    </div>

                    <div class="flex-end full-width">
                      <img v-if="item.img" :src="img(item.img)" class="flex-end"
                           :style="{'height': `${item.imgHeight}px`}">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-section">
        <div class="flex-center section-menu-container" ref="tabMenu" :style="fixedStyle">
          <div v-for="(menu, idx) in sectionMenu" :key="'section-menu-'+idx"
               class="unselect padding-top-20 padding-bottom-20 padding-left-24 padding-right-24 subtitle5"
               :class="{'sub2': Number(selectedId) !== Number(menu.id), 'selected': Number(selectedId) === Number(menu.id)}"
               @click="clickItem(menu.id)">
            {{ menu.name }}
          </div>
        </div>
        <div v-for="(section, idx) in sections" :key="'section-'+idx"
             :id="`section${section.id}`" style="padding: 120px 0"
             :class="{'bg-gray3': section.bgColor === 'gray3', 'bg-primary-light2': section.bgColor === 'primary-light2'}">

          <div class="container">
            <div v-if="(section.title && section.title !== '') || (section.subTitle && section.subTitle !== '')"
                 class="margin-bottom-40">
              <div v-if="section.title && section.title !== ''"
                   class="section-title main">{{ section.title }}
              </div>
              <div v-if="section.subTitle && section.subTitle !== ''"
                   class="section-title-bold main">{{ section.subTitle }}
              </div>
            </div>
            <div class="section-head-grid-container" v-if="section.head"
                 :style="{'grid-template-columns': section.head.img && section.head.img !== '' ? 'repeat(2, 1fr)' : '','grid-gap': section.head.img && section.head.img !== '' ? '0px 120px' : ''}">
              <div>
                <div v-if="section.head.primary && section.head.primary !== ''"
                     class="h8 primary margin-bottom-8">{{ section.head.primary }}
                </div>
                <div class="flex-align">
                  <div>
                    <div class="section-title main">{{ section.head.title }}</div>
                    <div class="section-title-bold main">{{ section.head.title2 }}</div>
                  </div>
                </div>
                <div v-if="section.head.label && section.head.label !== ''"
                     class="flex-center bg-primary body5-medium"
                     style="margin-top: 10px; display: inline-block; color: #fff; padding: 2px 8px 4px 8px; border-radius: 4px">
                  {{ section.head.label }}
                </div>
                <div v-if="section.head.desc && section.head.desc !== ''" class="body2 sub2 margin-top-24">{{
                  section.head.desc }}
                </div>
                <div v-if="section.head.text && section.head.text !== ''"
                     class="margin-top-16 body2 sub3">{{ section.head.text }}
                </div>
                <div v-if="section.head.link && section.head.link !== ''"
                     class="unselect flex-align margin-top-24" @click="routerPush(section.head.link)">
                  <div class="body2-medium main">자세히 보기</div>
                  <img :src="icon('draw_arrow-right')" class="margin-left-12 svg-sub3">
                </div>
              </div>
              <img v-if="section.head.img && section.head.img !== ''"
                   :src="img(section.head.img)">
            </div>

            <div v-if="section.content" class="margin-top-40">
              <div v-if="section.content.img && section.content.img !== ''"
                   :style="sectionContentPcStyle(section.content)">
                <div v-if="section.content.text && section.content.text !== ''"
                     class="body3 sub2">{{ section.content.text }}
                </div>
                <img :src="img(section.content.img)">
              </div>
              <div v-else-if="section.content.img === '' && section.content.text && section.content.text !== ''"
                   class="body3 sub2 margin-top-40">{{ section.content.text }}
              </div>
            </div>

            <div v-if="section.feature" :style="`margin-top: ${section.content ? '160' : '0'}px`">
              <div class="section-head-grid-container"
                   :style="{'alignItems': section.mainButton && section.mainButton.length > 0 ? 'flex-end' : 'center', 'grid-gap': section.feature.img && section.feature.img !== '' ? '0px 150px' : ''}">
                <div>
                  <div v-if="section.feature.primary && section.feature.primary !== ''"
                       class="h8 primary margin-bottom-8">{{ section.feature.primary }}
                  </div>
                  <div class="flex-align">
                    <div>
                      <div class="section-title main">{{ section.feature.title }}</div>
                      <div class="section-title-bold main">{{ section.feature.title2 }}</div>
                    </div>
                  </div>
                  <div v-if="section.feature.desc && section.feature.desc !== ''" class="body2 sub2 margin-top-24">{{
                    section.feature.desc }}
                  </div>

                  <div v-if="section.feature.text && section.feature.text !== ''"
                       class="margin-top-16 body2 sub3">{{ section.feature.text }}
                  </div>
                </div>
                <div v-if="section.feature.img && section.feature.img !== ''"
                     class="margin-top-40">
                  <img :src="img(section.feature.img)">
                </div>
              </div>
            </div>

            <div v-if="section.packs && section.packs.length > 0"
                 class="bg-white" style="padding: 100px 0">
              <div class="section-title-bold main">테마에 제공되는 기본 화면과 기능들을 확인 해 보세요!</div>
              <div class="tabs">
                <div v-for="(pack, idx) in section.packs" :key="`pack-${idx}`"
                     class="item-tab unselect" :class="{'item-tab-selected': idx === selectedPack}"
                     @click="selectedPack=idx">{{ pack.info.name }}
                </div>
              </div>
              <transition-group name="fade">
                <template v-for="(pack, idx) in section.packs">
                  <div v-if="idx === selectedPack" :key="'pack-'+idx">
                    <div v-if="pack.title !== '' || pack.title2 !== ''" style="margin-bottom: 60px">
                      <div class="section-title main">{{ pack.title }}</div>
                      <div class="section-title-bold main">{{ pack.title2 }}</div>
                    </div>

                    <div v-if="pack.info" class="pack-info-wrapper">
                      <div>
                        <div v-if="pack.info.label && pack.info.label !== ''"
                             class="body4-medium bg-primary-light2 primary"
                             style="margin-bottom: 12px; border-radius: 4px; text-align: center; width: 68px; height: 26px; padding: 2px 8px">
                          {{ pack.info.label }}
                        </div>
                        <div class="subtitle2 main">{{ pack.info.name }}</div>
                        <div class="body2 sub2" style="margin-top: 12px">{{ pack.info.desc }}</div>
                        <div v-if="pack.info.links && pack.info.links.length > 0"
                             class="margin-top-40 flex-align">
                          <div v-for="(link, idx) in pack.info.links"
                               :key="'link-'+idx" class="flex-align unselect"
                               @click="clickButton(link)"
                               :class="{'primary': idx === 0, 'sub': idx === 1, 'margin-left-40': idx === 1}">
                            <div class="body2-medium">{{ link.name }}</div>
                            <img :src="icon('u:arrow-up-right')" class="margin-left-4"
                                 :class="{'svg-primary': idx === 0, 'svg-sub': idx === 1}">
                          </div>

                        </div>
                      </div>
                      <img :src="img(pack.info.img)">
                    </div>

                    <div v-if="pack.imgs_pc && pack.imgs_pc.length > 0"
                         class="margin-top-60">
                      <div v-for="(pack_img, idx) in pack.imgs_pc"
                           :key="'pack_img-'+idx"
                           :class="{'margin-bottom-16': idx !== pack.imgs_pc.length - 1}">
                        <img v-if="pack_img.name !== ''" :src="img(pack_img.name)">
                      </div>
                    </div>

                    <div v-if="pack.button && Object.keys(pack.button).length > 0"
                         class="margin-top-60 flex-center">
                      <button class="body2-bold button is-primary"
                              style="height: 56px; padding: 16px 58px"
                              @click="clickButton(pack.button)">{{ pack.button.name }}
                      </button>
                    </div>
                  </div>
                </template>
              </transition-group>
            </div>

            <div v-if="section.plugins && section.plugins.length > 0"
                 style="margin-top: 100px">
              <div class="subtitle2 main" style="text-align: center">
                필요한 기능이 있다면 플러그인을 추가할 수 있습니다.
              </div>
              <div class="margin-top-40 plugins-wrapper">
                <div v-for="(plugin, idx) in section.plugins" :key="'plugin-'+idx"
                     class="flex-align bg-white"
                     style="padding: 20px; height: 88px; border-radius: 4px">
                  <div class="flex-center bg-gray3"
                       style="border-radius: 12px; width: 48px; height: 48px">
                    <img :src="icon(plugin.icon)" style="width: 24px; height: 24px">
                  </div>
                  <div class="margin-left-12 body3 sub">{{ plugin.name }}</div>

                </div>
              </div>
              <div class="margin-top-40 body4 sub3"
                   style="text-align: center">*더 많은 플러그인이 준비되어있으며, 팩과 플러그인은 지속적으로 업데이트됩니다.
              </div>
            </div>

            <div v-if="section.info" class="margin-top-60">
              <div class="section-grid-info">
                <img :src="img(section.info.img)">
                <div class="section-grid-info-item">
                  <div v-for="(menuItem, idx) in section.info.menu" :key="'menuItem-'+idx"
                       class="flex-align bg-white" style="padding: 16px">
                    <img :src="icon(menuItem.icon)" class="svg-sub2"
                         style="margin-right: 8px; width: 20px; height: 20px">
                    <div class="body4 sub2">{{ menuItem.name }}</div>
                  </div>
                </div>
              </div>
              <div style="margin-top: 120px">
                <div class="main section-title">{{ section.info.title }}</div>
                <div class="main section-title-bold">{{ section.info.title2 }}</div>
                <div class="body1 sub2 margin-top-24">{{ section.info.desc }}</div>
              </div>
            </div>

            <div v-for="(item, idx) in section.list" :key="'section-list-item'+idx"
                 class="section-grid-list" style="padding: 100px 0">
              <div v-if="item.img && item.img_direction === 'left'">
                <img :src="img(item.img)">
              </div>
              <div>
                <div class="body2-bold primary">{{ item.primary }}</div>
                <div class="h5 main" style="margin-top: 10px">{{ item.title }}</div>
                <div class="body2 sub2 margin-top-24">{{ item.desc }}</div>
                <div class="body4 sub3 margin-top-12">{{ item.info }}</div>
              </div>
              <div v-if="item.img && item.img_direction === 'right'">
                <img :src="img(item.img)">
              </div>
            </div>

            <div v-if="section.defaultPack"
                 class="section-grid-default-pack" style="padding: 160px 0 120px">
              <div>
                <div class="body2-bold primary">{{ section.defaultPack.primary }}</div>
                <div class="h5 main" style="margin-top: 10px">{{ section.defaultPack.title }}</div>
                <div class="body2 sub2 margin-top-24">{{ section.defaultPack.desc }}</div>
                <div class="body4 sub3 margin-top-12">{{ section.defaultPack.info }}</div>
              </div>
              <div style="margin-left: 100px" class="menuitem-wrapper-2">
                <div v-for="(menuItem, idx) in section.defaultPack.menu"
                     :key="'menuItem-'+idx" class="bg-white border-gray flex-align"
                     style="width: 200px; height: 54px; padding: 16px 20px">
                  <img :src="icon(menuItem.icon)" class="svg-sub2"
                       style="margin-right: 14px; width: 16px; height: 16px">
                  <div class="body4 main">{{ menuItem.name }}</div>
                </div>
              </div>
            </div>

            <div v-if="section.imgs && section.imgs.length > 0" style="padding:0 75px">
              <div class="content-slide" v-if="section.imgs">
                <slick ref="slick" :options="slickOptions">
                  <div v-for="(img, idx) in section.imgs"
                       :key="'slide-'+idx" class="slick-img-outer">
                    <img :src="img" alt="" class="slick-img" :style="slickImg">
                  </div>
                </slick>
              </div>
            </div>

          </div>

        </div>
      </div>

      <!--<div class="flex-center bottom-menu-btn-wrap" :style="fixedBottomStyle">
        <div v-for="(menu, idx) in bottomMenu" :key="'bottom-menu-'+idx"
             class="flex-align padding-top-20 padding-bottom-20"
             :style="bottomMenuStyle(bottomMenu, idx)">
          <div class="body2" :class="{'primary': idx === 0, 'main': idx === 1}">
            {{ menu.desc }}
          </div>
          <button class="button unselect flex-center flex-align h8 margin-left-16"
                  :class="{'is-primary': idx === 0, 'is-dark': idx === 1}"
                  style="width: 230px; height: 48px; border-radius: 8px"
                  @click="routerPush(`${menu.link}`)">
            <div class="h8" :class="{'primary': idx=== 1}">{{ menu.buttonText }}</div>
            <img :src="icon(menu.icon)" class="margin-left-4"
                 :class="{'svg-white': idx === 0, 'svg-primary': idx === 1}">
          </button>
        </div>
      </div>-->
    </div>
    <div class="mobile">
      <div class="container">
        <div class="intro bg-gray3" style="padding: 60px 20px">
          <div class="main">
            <div class="mo-pagetitle">{{ intro.text.title1 }}</div>
            <div class="mo-pagetitle-bold">{{ intro.text.title2 }}</div>
            <div class="mo-pagetitle">{{ intro.text.title3 }}</div>
          </div>
          <div class="margin-top-16 body3 sub2">{{ intro.text.content1 }}</div>
          <div class="margin-top-40">
            <div class="card-wrapper">
              <div v-for="(item, idx) in intro.list" :key="'card-item-'+idx"
                   class="card-info" :class="{'bg-white': !item.isDark, 'bg-dark': item.isDark}">
                <div class="h8" :class="{'main': !item.isDark}">{{ item.name }}</div>
                <div class="body5 margin-top-4" :class="{'sub2': !item.isDark}">{{ item.content }}</div>
                <div class="margin-top-12 flex-between"
                     style="align-items: flex-start">
                  <div class="unselect flex-align body4-medium"
                       :class="{'main': !item.isDark}" @click="clickItem(item.id)">
                    <div>자세히 보기</div>
                    <img :src="icon('draw_arrow-right')" class="margin-left-8"
                         :class="{'svg-main': !item.isDark, 'svg-white': item.isDark}">
                  </div>
                  <div class="flex-end">
                    <img v-if="item.img_mobile" :src="img(item.img_mobile)" class="flex-end">
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="main-section">
          <div class="flex-align section-menu-container" ref="tabMenuMobile" :style="fixedStyleMobile"
               style="padding: 0 16px; overflow: hidden">
            <div class="flex-align drag-scroll-mobile">
              <div v-for="(menu, idx) in sectionMenu" :key="'section-menu-'+idx"
                   class="unselect padding-top-12 padding-bottom-12 padding-left-16 padding-right-16 subtitle7 text-center"
                   :class="{'sub': Number(selectedId) !== Number(menu.id), 'selected': Number(selectedId) === Number(menu.id)}"
                   style="width: 300px; scroll-snap-align: start" @click="clickItem(menu.id)">
                {{ menu.name }}
              </div>
            </div>
          </div>
          <div v-for="(section, idx) in sections" :key="'section-'+idx"
               :id="`section-mobile-${section.id}`" style="padding: 60px 20px"
               :class="{'bg-gray3': section.bgColor === 'gray3', 'bg-primary-light2': section.bgColor === 'primary-light2'}">

            <div v-if="(section.title && section.title !== '') || (section.subTitle && section.subTitle !== '')"
                 class="margin-bottom-32">
              <div v-if="section.title && section.title !== ''"
                   class="mo-section-title main">{{ section.title }}
              </div>
              <div v-if="section.subTitle && section.subTitle !== ''"
                   class="mo-section-title-bold main">{{ section.subTitle }}
              </div>
            </div>

            <div class="head" v-if="section.head">
              <div v-if="section.head.primary && section.head.primary !== ''"
                   class="body4-bold primary margin-bottom-4">{{ section.head.primary }}
              </div>
              <div class="mo-section-title main">{{ section.head.title }}</div>
              <div class="flex-align">
                <div class="mo-section-title-bold main">{{ section.head.title2 }}</div>
              </div>
              <div v-if="section.head.label && section.head.label !== ''"
                   class="flex-center margin-top-8 bg-primary body5-medium"
                   style="display: inline-block; color: #fff; padding: 2px 8px 4px; border-radius: 4px">{{
                section.head.label }}
              </div>
              <div class="body2 main margin-top-16">{{ section.head.desc }}</div>
              <div v-if="section.head.text && section.head.text !== ''"
                   class="margin-top-16 body4 sub2">{{ section.head.text }}
              </div>
              <div v-if="section.id !== 4 && section.head.link && section.head.link !== ''"
                   class="unselect flex-align margin-top-16" @click="routerPush(section.head.link)">
                <div class="body4-bold main">자세히 보기</div>
                <img :src="icon('draw_arrow-right')" class="margin-left-12 svg-sub3">
              </div>
              <img v-if="section.head.img_mobile && section.head.img_mobile !== ''"
                   :src="img(section.head.img_mobile)" class="margin-top-40">
              <div v-if="section.mainButton && section.mainButton.length > 0"
                   class="flex-align margin-top-16">
                <button v-for="(button, idx) in section.mainButton"
                        :key="'mainButton-'+idx"
                        class="button flex-center unselect body4-medium"
                        :class="{'is-dark': idx === 0, 'is-gray': idx === 1}"
                        style="width: 50%; height: 40px; border-radius: 8px"
                        :style="mainButtonStyle(section.mainButton, idx)"
                        @click="clickButton(button)">
                  {{ button.name }}
                </button>
              </div>
            </div>

            <div v-if="section.content" class="margin-top-16">
              <div v-if="section.content.img_mobile && section.content.img_mobile !== ''">
                <img :src="img(section.content.img_mobile)">
              </div>
              <div class="body4 sub2 margin-top-16">{{ section.content.text }}</div>
            </div>

            <div v-if="section.feature" :class="{'margin-top-60': section.content}">
              <div class="head">
                <div v-if="section.feature.primary && section.feature.primary !== ''"
                     class="body4-bold primary margin-bottom-4">{{ section.feature.primary }}
                </div>
                <div class="mo-section-title main">{{ section.feature.title }}</div>
                <div class="flex-align">
                  <div class="mo-section-title-bold main">{{ section.feature.title2 }}</div>
                  <div v-if="section.feature.label && section.feature.label !== ''"
                       class="flex-center margin-left-8 bg-primary body5-medium"
                       style="color: #fff; padding: 2px 8px; border-radius: 4px">{{ section.head.label }}
                  </div>
                </div>
                <div v-if="section.feature.desc && section.feature.desc !== ''"
                     class="body2 main margin-top-16">{{ section.feature.desc }}
                </div>

                <div v-if="section.feature.text && section.feature.text !== ''"
                     class="margin-top-16 body4 sub2">{{ section.feature.text }}
                </div>
                <img v-if="section.feature.img_mobile && section.feature.img_mobile !== ''"
                     :src="img(section.feature.img_mobile)" class="margin-top-40">

              </div>
            </div>


            <div v-if="section.packs && section.packs.length > 0" style="padding:40px 0" class="bg-white">
              <div class="mo-section-title-bold main">테마에 제공되는 기본 화면과 기능들을 확인 해 보세요!</div>
              <div class="tabs">
                <div v-for="(pack, idx) in section.packs" :key="`pack-${idx}`"
                     class="item-tab unselect" :class="{'item-tab-selected': idx === selectedPack}"
                     @click="selectedPack=idx">{{ pack.info.name }}
                </div>
              </div>

              <transition-group name="fade">
                <template v-for="(pack, idx) in section.packs">
                  <div v-if="idx === selectedPack" :key="'pack-'+idx">

                    <div v-if="pack.title !== '' || pack.title2 !== ''"
                         style="margin-bottom: 40px">
                      <div class="mo-section-title main">{{ pack.title }}</div>
                      <div class="mo-section-title-bold main">{{ pack.title2 }}</div>
                    </div>

                    <img :src="img(pack.info.img_mobile)">
                    <div v-if="pack.info" style="margin-top: 16px">
                      <div>
                        <div v-if="pack.info.label_mobile && pack.info.label_mobile !== ''"
                             class="body6-medium bg-primary-light2 primary"
                             style="margin-bottom: 8px; border-radius: 4px; text-align: center; width: 110px; height: 22px; padding: 2px 8px">
                          {{ pack.info.label_mobile }}
                        </div>
                        <div class="h7 main">{{ pack.info.name }}</div>
                        <div class="body4 sub2" style="margin-top: 8px">{{ pack.info.desc }}</div>
                        <div v-if="pack.info.links && pack.info.links.length > 0"
                             class="margin-top-24 flex-align">
                          <button class="button is-dark flex-center"
                                  style="width: 100%; height: 40px"
                                  @click="clickButton(pack.info.links[0])">{{ pack.info.links[0].name }}
                          </button>
                        </div>
                      </div>

                    </div>

                    <div v-if="pack.imgs_mobile && pack.imgs_mobile.length > 0"
                         class="margin-top-40 flex-align"
                         style="overflow-x: auto; scroll-snap-type: x mandatory">
                      <img v-for="(pack_img, idx) in pack.imgs_mobile"
                           :key="'pack_img-'+idx" :src="img(pack_img.name)"
                           :class="{'margin-right-12': idx !== pack.imgs_mobile.length - 1}"
                           style="scroll-snap-align: start; border-radius: 8px; height: 200px">
                    </div>

                    <div v-if="pack.button && Object.keys(pack.button).length > 0"
                         class="margin-top-36 flex-center">
                      <button class="body2-medium button is-primary"
                              style="height: 52px; padding: 14px 30px"
                              @click="clickButton(pack.button)">{{ pack.button.name }}
                      </button>
                    </div>

                  </div>
                </template>
              </transition-group>
            </div>

            <div v-if="section.plugins && section.plugins.length > 0"
                 style="margin-top: 60px">
              <div class="mo-section-title main">필요한 기능이 있다면</div>
              <div class="mo-section-title-bold main">플러그인을 추가할 수 있습니다.</div>
              <div class="margin-top-20 plugins-wrapper">
                <template v-for="(plugin, idx) in section.plugins">
                  <div v-if="idx !== section.plugins.length - 1" :key="'plugin-'+idx"
                       class="flex-center bg-white"
                       style="height: 100px; padding: 0px 20px; border-radius: 4px">
                    <div style="text-align: center">
                      <img :src="icon(plugin.icon)" style="width: 24px; height: 24px">
                      <div class="margin-top-6 body5 sub2">{{ plugin.name }}</div>
                    </div>
                  </div>
                </template>
              </div>
              <div class="margin-top-20 body6 sub3">
                *더 많은 플러그인이 준비되어있으며, 팩과 플러그인은 지속적으로 업데이트됩니다.
              </div>
            </div>

            <!-- <div v-if="section.info">
              <div style="padding: 20px 20px 32px"
                class="bg-dark margin-top-32">
                <div class="menuitem-wrapper">
                  <div v-for="(menuItem, idx) in section.info.menu"
                    :key="'menuItem-'+idx" class="flex-align bg-white"
                    style="height: 40px; padding: 12px 16px">
                    <img :src="icon(menuItem.icon)" class="svg-gray"
                      style="margin-right: 6px; width: 16px; height: 16px">
                    <div class="body7" style="color: #50545c">{{ menuItem.name }}</div>
                  </div>
                </div>
                <div class="flex-align margin-top-40">
                  <div class="col-12">
                    <img :src="img(section.info.img_mobile)">
                  </div>
                </div>
              </div>
              <div class="margin-top-20">
                <div class="h8 main">{{ section.info.title }}</div>
                <div class="body4 sub2 margin-top-8">{{ section.info.desc }}</div>
              </div>
            </div> -->

            <div v-if="section.info" class="margin-top-40">
              <div class="section-grid-info-item">
                <template v-for="(menuItem, idx) in section.info.menu">
                  <div v-if="idx !== section.info.menu.length - 1" :key="'menuItem-'+idx"
                       class="flex-align" style="background: #fff; padding: 12px 16px">
                    <img :src="icon(menuItem.icon)" class="svg-sub2"
                         style="margin-right: 8px; width: 20px; height: 16px">
                    <div class="body4 sub2">{{ menuItem.name }}</div>
                  </div>

                </template>
              </div>
              <div style="margin-top: 50px">
                <img :src="img(section.info.img)">
              </div>
              <div class="margin-top-60">
                <div class="main mo-section-title">{{ section.info.title }}</div>
                <div class="main mo-section-title-bold">{{ section.info.title2 }}</div>
                <div class="body4 sub2 margin-top-8">{{ section.info.desc }}</div>
              </div>
            </div>

            <div v-for="(item, idx) in section.list" :key="'section-list-item'+idx"
                 style="padding-top: 60px">
              <div v-if="item.img_mobile && item.img_mobile !== ''">
                <img :src="img(item.img_mobile)">
              </div>
              <div class="margin-top-20">
                <div v-if="item.primary && item.primary !== ''"
                     class="body4-bold primary margin-bottom-4">{{ item.primary }}
                </div>
                <div class="h8 main">{{ item.title }}</div>
                <div class="body4 sub2 margin-top-8">{{ item.desc }}</div>
                <div class="body6 sub3 margin-top-8">{{ item.info }}</div>
              </div>
            </div>

            <div v-if="section.defaultPack" class="margin-top-60">
              <div>
                <div class="body4-bold primary margin-bottom-4">{{ section.defaultPack.primary }}</div>
                <div class="h8 main">{{ section.defaultPack.title }}</div>
                <div class="body4 sub2 margin-top-8">{{ section.defaultPack.desc }}</div>
                <div class="body6 sub3 margin-top-8">{{ section.defaultPack.info }}</div>
              </div>
              <div class="margin-top-20 section-defaultpack-menu">
                <div v-for="(menuItem, idx) in section.defaultPack.menu"
                     :key="'menuItem-'+idx" class="bg-white border-gray flex-align"
                     style="height: 54px; padding: 16px 12px">
                  <img :src="icon(menuItem.icon)" class="svg-sub2"
                       style="margin-right: 8px; width: 20px; height: 20px">
                  <div class="body4 main">{{ menuItem.name }}</div>
                </div>
              </div>
            </div>

            <div v-if="section.imgs && section.imgs.length>0"
                 class="content is-12" style="padding-top: 60px">
              <div class="content-slide" v-if="section.imgs">
                <scroll-wrapper>
                  <div class="flex-align">
                    <template v-for="(img, idx) in section.imgs">
                      <img :src="img" alt="" class="slick-img"
                           style="width:260px;height:385px;margin:0 6px;border-radius: 12px" :key="'slide-'+idx">
                    </template>
                  </div>
                </scroll-wrapper>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-align bottom-menu-btn-wrap" style="padding: 12px 16px"
           :style="fixedBottomStyle">
        <button v-for="(menu, idx) in bottomMenu" :key="'bottom-menu-'+idx"
                class="button unselect flex-center body4-bold"
                :class="{'is-primary': idx === 0, 'is-dark': idx === 1}"
                style="height: 48px" @click="clickBottom(menu.link)">
          <div :class="{'primary': idx === 1}">{{ menu.buttonText }}</div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import Slick from 'vue-slick'
  import ScrollWrapper from "../module/ScrollWrapper";

  export default {
    name: "PlatformfyInfo",
    components: {
      ScrollWrapper,
      Slick,
    },
    watch: {},
    data() {
      return {
        popupMarket: undefined,
        slickOptions: {
          centerMode: false,
          variableWidth: false,
          infinite: true,
          autoplay: false,
          centerPadding: '20px',
          slidesToShow: 3,
          slidesToScroll: 1,
          speed: 600,
          prevArrow: '<div class="arrow-left"><img class="arrow-img" src="/static/icon/fi_chevron-left.svg" @click="prev"></div>',
          nextArrow: '<div class="arrow-right"><img class="arrow-img" src="/static/icon/fi_chevron-right.svg" @click="next"></div>',
          responsive: [
            {
              breakpoint: 767,
              settings: {
                autoplay: true,
                slidesToShow: 1,
                centerMode: true,
                dots: false,
                arrows: false
              }
            }
          ]
        },
        defaultPackId: 70,
        selectedId: 1,
        fixedState: false,
        mobileFixedState: false,
        intro: {
          list: [
            {
              name: '쇼핑몰, 홈페이지가 아니라\n' +
                '온라인 서비스를 만들 수 있는 다양한 플랫폼 팩',
              content: '창업자들이 원하는 형태의 플랫폼 웹/앱 서비스를 만들 수 있도록\n'+
              '다양한 플랫폼 팩을 제공하며, 앞으로도 계속해서 팩은 추가됩니다. ',
              id: 3,
              img: 'img_platform_pack',
              img_mobile: 'img_platform_pack-mobile',
              imgHeight: 102,
              class: false,
              isDark: false,
            },
            {
              name: '기획, 디자인, 프론트엔드 개발 대신\n' +
                '디자인 템플릿 빌더',
              content: '주요 페이지 디자인을 수정할 수 있도록\n' +
                '블럭 템플릿을 제공합니다.',
              id: 1,
              img: 'img_design_builder',
              img_mobile: 'img_design_builder-mobile',
              imgHeight: 168,
              class: true,
              isDark: false
            },
            /* {
              name: '플랫폼 운영 솔루션',
              content: '플랫폼의 회원, 파트너, 주문,\n' +
                '배너 컨텐츠 등을 관리할 수 있습니다.',
              id: 2,
              img: 'img_platform_solution',
              img_mobile: 'img_platform_solution-mobile',
              isDark: true,
              class: false
            }, */

            {
              name: '서버, 백엔드 개발 대신\n' +
                '플랫폼 관리자사이트',
              content: '플랫폼, 그리고 플랫폼에 등록된 파트너들의 회원, 주문, 문의,\n' +
                '배너 컨텐츠 등을 관리할 수 있습니다.',
              id: 2,
              img: 'img_platform_admin',
              img_mobile: 'img_platform_admin-mobile',
              imgHeight: 126,
              isDark: false,
              class: false
            },
            {
              name: '주문 내역 엑셀파일을 메일로 보내는 대신 \n입점 파트너 관리자사이트에서',
              content: '플랫폼에 입점한 파트너들이 기본 정보와 상품을 등록하고,\n'+
              '날마다 들어온 주문과 문의에 대한 답변을\n직접 파트너 관리자사이트에서 할 수 있습니다.',
              id: 3,
              img: 'img_platform_partner_admin',
              img_mobile: 'img_platform_partner_admin-mobile',
              imgHeight: 126,
              isDark: false,
              class: false
            },
            {
              name: '추가 개발 대신\n' +
                '앱 마켓',
              content: '제작한 서비스에 추가기능이 필요할 때\n' +
                '개발 없이 원하는 기능만 구매해서 붙일 수 있습니다.',
              id: 4,
              img: 'img_app_market',
              img_mobile: 'img_app_market-mobile',
              imgHeight: 120,
              class: true,
              isDark: false
            },
            {
              name: '그리고\n' +
                '케어서비스',
              content: '좀 더 전문적인 케어가 필요한 팀을 위해\n' +
                '개발팀이 없어도 초기 비즈니스를 안정적으로 할 수 있도록\n' +
                '디자인, 개발 불문하고 지원해 드립니다.',
              id: 5,
              img: 'img_care_service',
              img_mobile: 'img_care_service-mobile',
              imgHeight: 130,
              class: false,
              isDark: false
            },
          ],
          text: {
            title1: '플랫폼파이로 플랫폼을 런칭하는 과정에',
            title2: '개발자가 필요 없는 이유를 소개합니다',
            title3: '',
            content1: '플랫폼파이는 누구나 아이디어를 구현하고, 시장을 검증할 수 있도록 하기 위해 오랜 시간 연구해왔습니다.'
          }
        },
        sectionMenu: [
          {
            id: 1,
            name: '디자인 편집 빌더'
          },
          {
            id: 2,
            name: '플랫폼 운영 솔루션'
          },
          {
            id: 3,
            name: '기본 플랫폼 파이 테마 소개'
          },
          {
            id: 4,
            name: '플러그인'
          },
          {
            id: 5,
            name: '케어서비스'
          }
        ],
        bottomMenu: [
          {
            buttonText: '무료체험',
            icon: 'u:arrow-up-right',
            link: '/theme_market',
            desc: '쉽고 빠르게 아이디어를 실현해 보고 싶다면?'
          },
          {
            buttonText: 'Prime',
            icon: 'u:arrow-up-right',
            link: '/prime',
            desc: '나에게 꼭 맞춘 커스텀 플랫폼을 만들고 싶다면?'
          }
        ],
        selectedPack: 0,
        sections: [
          {
            id: 1,
            bgColor: 'white',
            circle: '#17FF90',
            mainButton: [],
            title: '',
            subTitle: '',
            img: 'img_page_order_make',
            tablet_img: 'img_page_order_make_tablet',
            head: {
              primary: '',
              title: '최신 디자인 트렌드가 적용된 블럭을 추가하고',
              title2: '내 서비스에 맞는 템플릿으로 변경할 수 있어요',
              desc: '일반적인 플랫폼 사이트 주요 페이지 디자인 구성이 다들 비슷하다는 사실을 아시나요?\n' +
                '플랫폼파이는 플랫폼 서비스에서 범용적으로 쓰이는 블럭을 추가하고, 최신 트렌드가 반영된 템플릿을 제공해서 누구나 템플릿만 선택하면 디자인이 완성될 수 있도록 하였습니다.'
            },
            list: [
              {
                img: 'builder_img_1',
                img_mobile: 'builder_img_1',
                img_direction: 'right',
                primary: 'Simple',
                icon: 'u_file-edit-alt',
                title: '기본 디자인에 템플릿 변경만 하면\n' +
                  '화면 디자인 완성!',
                title2: '',
                desc: '디자인을 몰라도 상관 없어요.\n' +
                  '내 서비스에 필요한 블럭을 추가하거나 기본 디자인 스타일 템플릿을 변경하기만 하면 내 화면 디자인 완성!'
              },
              {
                img: 'builder_img_2',
                img_mobile: 'builder_img_2_mobile',
                img_direction: 'right',
                primary: 'Style Design',
                icon: 'u_comment-alt-check',
                title: '브랜드 로고, 컬러, 폰트를 설정해\n'+
                  '내 서비스만의 아이덴티티를 표현해요',
                title2: '',
                desc: '내 브랜드에 맞춰 사이트 로고, 컬러를 설정해보세요.\n'+
                  '유저들에게 브랜드 아이덴티티가 전달될거예요.\n'+
                  '브랜드 로고, 컬러는 언제든지 변경 할 수 있습니다.',
                info: ''
              },
              {
                img: 'builder_img_3',
                img_mobile: 'builder_img_3',
                img_direction: 'right',
                primary: 'Custom design',
                icon: 'u_expand-from-corner',
                title: '원하는 디자인이 없다면\n' +
                  '커스텀 디자인을 요청하세요!',
                title2: '',
                desc: '원하는 디자인 템플릿이 없다면 내 서비스에 필요한 커스텀 디자인을 요청하고 받아보세요. 디자인부터 페이지 개발까지 디자인 빌더에서 올인원으로 해결!',
                info: '커스텀 디자인에는 별도 비용이 청구됩니다. '
              }
            ],
            links: {}
          },
          {
            id: 2,
            bgColor: 'gray3',
            circle: '#7B61FF',
            title: '',
            subTitle: '',
            mainButton: [],
            img: '',
            tablet_img: '',
            head: {
              primary: '',
              title: '서비스 뿐만 아니라 관리자 사이트까지 제공되어',
              title2: '플랫폼 운영에 필요한 관리 기능을 고민하고 제작하느라 추가개발 할 필요가 없어요.',
              desc: ''
            },
            info: {
              // title: '플랫폼 운영 솔루션은 플랫폼을 운영하는데 필요한 관리자 사이트예요.',
              // title2: '관리자 사이트에 플랫폼 운영에 필요한 기능이 이미 포함되어 있어요.',
              // desc: '플랫폼파이에서는 기획과 개발에서 많은 리소스가 필요한  플랫폼 관리/운영 솔루션 이 이미 구축되어 있습니다.\n' +
              //   '추가 기획/개발 비용 없이 플랫폼 사업 운영의 노하우가 집약된 플랫폼파이의 운영솔루션 팩과 함께  효율적으로 플랫폼 서비스를 운영해보세요!',
              img: 'notebook',
              img_mobile: 'notebook',
              menu: [
                {
                  icon: 'u:panel-add',
                  name: '대쉬보드'
                },
                {
                  icon: 'u:phone-volume',
                  name: '전화연결'
                },
                {
                  icon: 'u:comment-dots',
                  name: '고객센터'
                },
                {
                  icon: 'u:setting',
                  name: '서비스 관리'
                },
                {
                  icon: 'u:filter',
                  name: '필터/정렬'
                },
                {
                  icon: 'u:fast-mail',
                  name: '메일/SNS 전송'
                },
                {
                  icon: 'u:clipboard-notes',
                  name: '게시판 관리'
                },
                {
                  icon: 'u:money-withdraw',
                  name: '정산 관리'
                },
                {
                  icon: 'u:box',
                  name: '상품 관리'
                },
                {
                  icon: 'u:user',
                  name: '회원 관리'
                },
                {
                  icon: 'u:apps',
                  name: '카테고리'
                },
                {
                  icon: 'u:edit-alt',
                  name: '후기/문의'
                },
                {
                  icon: 'u:users-alt',
                  name: '파트너 관리'
                },
                {
                  icon: 'u:calender',
                  name: '예약 관리'
                },
                {
                  icon: 'u:file-check',
                  name: '주문관리'
                },
              ],
            },
            // list: [
            //   {
            //     icon: 'u:chart-down',
            //     img: 'img_platform_solution_1',
            //     img_mobile: 'img_platform_solution_1_mobile',
            //     img_direction: 'right',
            //     title: '플랫폼 서비스는\n' +
            //       '웹/앱만 있으면 되는 게 아닙니다.',
            //     title2: '',
            //     desc: '플랫폼 서비스를 관리할 수 있는 사이트와 파트너들이 본인의 상품과 정보를 운영할 수 있는 파트너 관리자 사이트가 있어야 제대로 된 비즈니스를 할 수 있습니다.'
            //   },
            //   {
            //     icon: 'fi:edit-3',
            //     img: 'img_platform_solution_2',
            //     img_mobile: 'img_platform_solution_2',
            //     img_direction: 'left',
            //     title: '플랫폼 서비스 전용 솔루션이 필요한 이유',
            //     title2: '',
            //     desc: '플랫폼 서비스 창업을 준비하시는 분들이 항상 겪는 문제가 있습니다.\n' +
            //       '처음 서비스를 기획할 때 생각했던 부분만으로는 실제로 서비스를 운영·관리할 수 없다는 것입니다.\n' +
            //       '런칭을 위해, 안정적인 서비스 운영을 위해  추가로 고려해야하는 사항이 끝도 없이 발생하게 됩니다.',
            //   },
            // ],
            links: {}
          },
          {
            id: 3,
            bgColor: 'white',
            circle: '#FF6600',
            feature: {
              primary: '플랫폼파이 가능 기능',
              title: '프로토타입 정도가 아니예요.',
              title2: '플랫폼파이로 만들면 비즈니스가 가능한 내 서비스가 만들어집니다.',
              desc: '',
              img: 'img_defaultpack_feature',
              img_mobile: 'img_defaultpack_feature_mobile'
            },

            packs: [
              {
                title: '',
                title2: '',
                info: {
                  name: '상품 택배 주문 테마',
                  label: '',
                  img: 'img_pack_info_delivery',
                  img_mobile: 'img_pack_info_delivery_mobile',
                  desc: '유저가 파트너들의 상품 구매 및\n' +
                    '택배 배송 요청을 받을 수 있습니다.',
                  links: [
                    {
                      name: '제작사례 보기',
                      class: 'is-dark',
                      linkType: 'outlink',
                      link: 'https://lpthemedelivery.launchpack.co.kr/home'
                    },
                    {
                      name: '자세히 보기',
                      class: '',
                      linkType: 'link',
                      link: '/theme_detail?id=188'
                    },
                  ]
                },
                button: {
                  name: '14일 무료체험으로 직접 확인 해 보기',
                  linkType: 'link',
                  link: '/theme_market'
                },
                imgs_pc: [
                  {
                    name: 'img_delivery_detail_pc_1'
                  },
                  {
                    name: 'img_delivery_detail_pc_2'
                  },
                  {
                    name: 'img_delivery_detail_pc_3'
                  },
                ],
                imgs_mobile: [
                  {
                    name: 'img_delivery_detail_mobile_1'
                  },
                  {
                    name: 'img_delivery_detail_mobile_2'
                  },
                  {
                    name: 'img_delivery_detail_mobile_3'
                  },
                  {
                    name: 'img_delivery_detail_mobile_4'
                  },
                  {
                    name: 'img_delivery_detail_mobile_5'
                  },
                  {
                    name: 'img_delivery_detail_mobile_6'
                  },
                  {
                    name: 'img_delivery_detail_mobile_7'
                  },
                  {
                    name: 'img_delivery_detail_mobile_8'
                  },
                  {
                    name: 'img_delivery_detail_mobile_9'
                  },
                  {
                    name: 'img_delivery_detail_mobile_10'
                  },
                ]
              },
              {
                title: '',
                title2: '',
                info: {
                  name: '서비스 예약 테마 (시간단위)',
                  label: '',
                  img: 'img_pack_info_reservation',
                  img_mobile: 'img_pack_info_reservation_mobile',
                  desc: '시/분 단위로 유저가 파트너의 서비스 상품을\n' +
                    '예약할 수 있습니다.',
                  links: [
                    {
                      name: '제작사례 보기',
                      class: 'is-dark',
                      linkType: 'outlink',
                      link: 'https://lpthemereservation.launchpack.co.kr/home'
                    },
                    {
                      name: '자세히 보기',
                      class: '',
                      linkType: 'link',
                      link: '/theme_detail?id=190'
                    },
                  ]
                },
                button: {
                  name: '14일 무료체험으로 직접 확인 해 보기',
                  linkType: 'link',
                  link: '/theme_market'
                },
                imgs_pc: [
                  {
                    name: 'img_reservation_detail_pc_1'
                  },
                  {
                    name: 'img_reservation_detail_pc_1'
                  },
                ],
                imgs_mobile: [
                  {
                    name: 'img_reservation_detail_mobile_1'
                  },
                  {
                    name: 'img_reservation_detail_mobile_2'
                  },
                  {
                    name: 'img_reservation_detail_mobile_3'
                  },
                  {
                    name: 'img_reservation_detail_mobile_4'
                  },
                  {
                    name: 'img_reservation_detail_mobile_5'
                  },
                  {
                    name: 'img_reservation_detail_mobile_6'
                  },
                  {
                    name: 'img_reservation_detail_mobile_7'
                  },
                  {
                    name: 'img_reservation_detail_mobile_8'
                  },
                ]
              },
              {
                title: '',
                title2: '',
                info: {
                  name: '서비스 예약 테마 (기간단위)',
                  label: '',
                  img: 'img_pack_info_booking',
                  img_mobile: 'img_pack_info_booking_mobile',
                  desc: '기간(일) 단위로 유저가 파트너들의 서비스 상품을\n' +
                    '예약할 수 있습니다.',
                  links: [
                    {
                      name: '제작사례 보기',
                      class: 'is-dark',
                      linkType: 'outlink',
                      link: 'https://lpthemebooking.launchpack.co.kr/home'
                    },
                    {
                      name: '자세히 보기',
                      class: '',
                      linkType: 'link',
                      link: '/theme_detail?id=189'
                    },
                  ]
                },
                button: {
                  name: '14일 무료체험으로 직접 확인 해 보기',
                  linkType: 'link',
                  link: '/theme_market'
                },
                imgs_pc: [
                  {
                    name: 'img_booking_detail_pc_1'
                  },
                  {
                    name: 'img_booking_detail_pc_1'
                  },
                ],
                imgs_mobile: [
                  {
                    name: 'img_booking_detail_mobile_1'
                  },
                  {
                    name: 'img_booking_detail_mobile_2'
                  },
                  {
                    name: 'img_booking_detail_mobile_3'
                  },
                  {
                    name: 'img_booking_detail_mobile_4'
                  },
                  {
                    name: 'img_booking_detail_mobile_5'
                  },
                  {
                    name: 'img_booking_detail_mobile_6'
                  },
                  {
                    name: 'img_booking_detail_mobile_7'
                  },
                  {
                    name: 'img_booking_detail_mobile_8'
                  },
                ]
              },
              {
                title: '',
                title2: '',
                info: {
                  name: '전화연결 테마',
                  /*label: '기본제공',
                  label_mobile: '기본제공 플랫폼 팩',*/
                  img: 'img_pack_info_default',
                  img_mobile: 'img_pack_info_default_mobile',
                  desc: '유저가 파트너의 서비스 상품을\n' +
                    '전화로 주문할 수 있습니다.',
                  links: [
                    {
                      name: '제작사례 보기',
                      class: 'is-dark',
                      linkType: 'outlink',
                      link: 'https://lpthemecall.launchpack.co.kr/home'
                    },
                    {
                      name: '자세히 보기',
                      class: '',
                      linkType: 'link',
                      link: '/theme_detail?id=191'
                    },
                  ]
                },
                button: {
                  name: '14일 무료체험으로 직접 확인 해 보기',
                  linkType: 'link',
                  link: '/theme_market'
                },
                imgs_pc: [
                  {
                    name: 'img_defaultpack_detail_pc_1'
                  },
                  {
                    name: 'img_defaultpack_detail_pc_2'
                  }
                ],
                imgs_mobile: [
                  {
                    name: 'img_defaultpack_detail_mobile_1'
                  },
                  {
                    name: 'img_defaultpack_detail_mobile_2'
                  },
                  {
                    name: 'img_defaultpack_detail_mobile_3'
                  },
                  {
                    name: 'img_defaultpack_detail_mobile_4'
                  },
                  {
                    name: 'img_defaultpack_detail_mobile_5'
                  },
                  {
                    name: 'img_defaultpack_detail_mobile_6'
                  },
                  {
                    name: 'img_defaultpack_detail_mobile_7'
                  }
                ]
              },
            ],
            imgs: [],
            links: {}
          },
          {
            id: 4,
            bgColor: 'primary-light2',
            title: '플랫폼파이는 누구나 합리적 가격으로 런칭 할 수 있도록',
            subTitle: '불필요한 기능 없이, 새로운 기능이 필요한 고객만 플러그인을 추가하여 이용할 수 있게 제공합니다.',
            img: 'img_page_api',
            tablet_img: 'img_page_api_tablet',
            head: {
              primary: '',
              title: '',
              title2: '플러그인',
              label: '무료체험 기간 동안 플러그인도 무료!',
              link: '/market/list',
              img: 'img_appmarket_head',
              img_mobile: 'img_appmarket_head_mobile',
              desc: '플랫폼파이에서는 기본 팩에 다양한 디자인, 기능 조합을 추가할 수 있는 플러그인을 이용할 수 있어요.\n' +
                '지금 바로 14일 동안 플러그인에서 모든 기능을  추가해보며 내 서비스를 구현해 보고 이용을 결정해보세요!'
            },
            mainButton: [],
            apps: [],
            packs: [],
            plugins: [
              {
                icon: 'u:file-alt-color',
                name: '추가 게시판'
              },
              {
                icon: 'u:clipboard-alt-color',
                name: '공지사항 팝업'
              },
              {
                icon: 'u:user-color',
                name: '본인인증'
              },
              {
                icon: 'u:comment-lines-color',
                name: '앱 푸시 알림'
              },
              {
                icon: 'u:folder-upload-color',
                name: '상품 엑셀파일 업로드'
              },

              {
                icon: 'u:user-square-color',
                name: '파트너 기획전'
              },
              {
                icon: 'u:user-check-color',
                name: '성인인증'
              },
              {
                icon: 'u:brightness-plus-color',
                name: '적립금/쿠폰 관리'
              },
              {
                icon: 'u:database-alt-color',
                name: '상품 맞춤 DB관리'
              },
              {
                icon: 'u:database-alt-color',
                name: '파트너 맞춤 DB관리'
              },
              {
                icon: 'u:database-alt-color',
                name: '회원 맞춤 DB관리'
              },
              {
                icon: 'u:image-upload-color',
                name: '상품 이미지 일괄 등록'
              },
              {
                icon: 'u:product-recent-color',
                name: '최근 본 상품'
              },
              {
                icon: 'u:partner-product-color',
                name: '파트너별 상품 기획전'
              },
              {
                icon: 'u:print-color',
                name: '주문 상세페이지 인쇄'
              },
              {
                icon: 'plugin:naver-pay',
                name: '네이버페이'
              }
            ],
            list: [],
            links: {}
          },
          {
            id: 5,
            bgColor: 'white',
            circle: '#FF6600',
            title: '그래도 도움이 필요하시다면,',
            subTitle: '저희가 직접 도와드리겠습니다.',
            img: '',
            tablet_img: '',
            head: {
              primary: '',
              title: '',
              title2: '케어서비스',
              desc: '플랫폼파이에서는 기본 서비스 보안, 유지 보수, 관리자 사이트 1:1 문의 서비스 제공을 통해 고객이 원활하게 서비스를 운영할 수 있도록 돕고 있어요.',
              text: '전문가 없이도 서비스를 수정하고, 에러를 안정적으로 해결할 수 있도록 기획, 개발, 디자인 등의 추가 용역을 충전금으로 이용할 수 있는 케어서비스를 제공하고 있어요. 케어서비스를 이용해 개발자, 디자이너, 기획자 없이도 내 서비스를 안정적으로 유지하고 확장해보세요!',
              img: 'img_careservice_head',
              img_mobile: 'img_careservice_head',
              link: '/care_service/intro',
            },
            content: {
              text: ''
            },
            mainButton: [],
            list: [],
            imgs: [],
            links: {}
          },
        ]
      }
    },
    created() {
    },
    mounted() {
      let isMobile = matchMedia("(max-width: 1024px)").matches;
      if (isMobile) {
        document.getElementsByClassName('btn-top')[0].style.setProperty('bottom', '80px', 'important');
        window.addEventListener('scroll', this.detectClientHeightMobile);
      } else {
        window.addEventListener('scroll', this.detectClientHeight);
      }
      this.$nextTick(() => {
        if (this.$route.query.id) {
          setTimeout(() => {
            this.clickItem(this.$route.query.id)
          }, 500)
        }
        setTimeout(() => {
          document.getElementById('ch-plugin').style.display = 'none';
        }, 1000);
      })
    },
    beforeDestroy() {
      document.getElementById('ch-plugin').style.display = '';
      let isMobile = matchMedia("(max-width: 1024px)").matches;
      if (isMobile) {
        window.removeEventListener('scroll', this.detectClientHeightMobile);
      } else {
        window.removeEventListener('scroll', this.detectClientHeight);
      }
    },
    methods: {
      clickBottom(link) {
        if(link === '/prime') {
          const a = document.createElement("a");
          //a.href = 'https://pfa.launchpack.co.kr/prime';
          a.href = 'https://launchpack.co.kr/prime';
          a.target = '_blank';
          document.body.appendChild(a);
          a.click();
          a.parentNode.removeChild(a);
        } else {
          this.routerPush(link);
        }
      },
      addMessageEvent() {
        window.addEventListener('message', this.onMessageMarket);
      },
      removeMessageEvent() {
        window.removeEventListener('message', this.onMessageMarket);
      },
      onMessageMarket(e) {
        if (e.data.close) {
          this.popupMarket.close();
          this.removeMessageEvent();
          if (e.data.redirectUrl) {
            setTimeout(() => {
              window.open(e.data.redirectUrl);
            }, 300);
          }
        }
      },
      next() {
        this.$refs.slick.next();
      },

      prev() {
        this.$refs.slick.prev();
      },
      detectClientHeight() {
        this.$nextTick(() => {
          let scrollTop = document.documentElement.scrollTop;
          if (this.$refs.tabMenu) {
            this.fixedState = scrollTop > this.$refs.tabMenu.getBoundingClientRect().top + 1500;
          }
          let section1 = document.getElementById('section1').getBoundingClientRect().top + window.pageYOffset;
          let section2 = document.getElementById('section2').getBoundingClientRect().top + window.pageYOffset;
          let section3 = document.getElementById('section3').getBoundingClientRect().top + window.pageYOffset;
          let section4 = document.getElementById('section4').getBoundingClientRect().top + window.pageYOffset;
          let section5 = document.getElementById('section5').getBoundingClientRect().top + window.pageYOffset;
          if (this.fixedState && this.$refs.tabMenu) {
            section1 -= this.$refs.tabMenu.clientHeight;
            section2 -= this.$refs.tabMenu.clientHeight + 120;
            section3 -= this.$refs.tabMenu.clientHeight + 120;
            section4 -= this.$refs.tabMenu.clientHeight + 120;
            section5 -= this.$refs.tabMenu.clientHeight + 120;
          }

          if (scrollTop > section1) {
            this.selectedId = 1
          }
          if (scrollTop > section2) {
            this.selectedId = 2
          }
          if (scrollTop > section3) {
            this.selectedId = 3
          }
          if (scrollTop > section4) {
            this.selectedId = 4
          }
          if (scrollTop > section5) {
            this.selectedId = 5
          }
        })
      },
      detectClientHeightMobile() {
        this.$nextTick(() => {
          let scrollTop = document.documentElement.scrollTop;
          if (this.$refs.tabMenuMobile) {
            this.mobileFixedState = scrollTop > this.$refs.tabMenuMobile.getBoundingClientRect().top + 1500;
          }

          let section1 = document.getElementById('section-mobile-1').getBoundingClientRect().top + window.pageYOffset;
          let section2 = document.getElementById('section-mobile-2').getBoundingClientRect().top + window.pageYOffset;
          let section3 = document.getElementById('section-mobile-3').getBoundingClientRect().top + window.pageYOffset;
          let section4 = document.getElementById('section-mobile-4').getBoundingClientRect().top + window.pageYOffset;
          let section5 = document.getElementById('section-mobile-5').getBoundingClientRect().top + window.pageYOffset;

          let tabMenu = document.getElementsByClassName('drag-scroll-mobile')[0];

          if (this.fixedState && this.$refs.tabMenuMobile) {
            section1 -= this.$refs.tabMenuMobile.clientHeight;
            section2 -= this.$refs.tabMenuMobile.clientHeight + 120;
            section3 -= this.$refs.tabMenuMobile.clientHeight + 120;
            section4 -= this.$refs.tabMenuMobile.clientHeight + 120;
            section5 -= this.$refs.tabMenuMobile.clientHeight + 120;

          }

          if (scrollTop > section1) {
            this.selectedId = 1;
            tabMenu.scrollLeft = tabMenu.children[0].offsetLeft;
          }
          if (scrollTop + 120 > section2) {
            this.selectedId = 2;
            tabMenu.scrollLeft = tabMenu.children[1].offsetLeft;
          }
          if (scrollTop + 120 > section3) {
            this.selectedId = 3;
            tabMenu.scrollLeft = tabMenu.children[2].offsetLeft;
          }
          if (scrollTop + 120 > section4) {
            this.selectedId = 4;
            tabMenu.scrollLeft = tabMenu.children[3].offsetLeft;
          }
          if (scrollTop + 120 > section5) {
            this.selectedId = 5;
            tabMenu.scrollLeft = tabMenu.children[4].offsetLeft;
          }
        })
      },
      clickItem(id) {
        // this.selectedId = id;
        let element = undefined;
        let offset = undefined;
        if (matchMedia("(max-width: 1024px)").matches) {
          element = document.getElementById(`section-mobile-${id}`)
          offset = window.pageYOffset + element.getBoundingClientRect().top - 60;
        } else {
          element = document.getElementById(`section${id}`);
          offset = window.pageYOffset + element.getBoundingClientRect().top - 64;
        }
        if (!this.fixedState) {
          offset -= this.$refs.tabMenu.clientHeight;
        }
        if (!this.mobileFixedState) {
          offset -= this.$refs.tabMenuMobile.clientHeight;
        }
        window.scrollTo({
          top: offset,
          behavior: 'smooth'
        })
      },
      clickButton(item) {
        if (item.link.indexOf('/market/detail') > -1) {
          let width = 1231;
          let height = 760;
          this.popupMarket = window.open(item.link, 'appMarket', `width=${width}, height=${height}, top=0, left=0, menubar=no, toolbar=no, titlebar=yes`)
          this.addMessageEvent();
        } else if (item.linkType === 'outlink') {
          const a = document.createElement('a');
          a.href = item.link;
          a.target = '_blank';
          a.style.display = 'none';
          document.body.appendChild(a);
          a.click();
          a.parentNode.removeChild(a);
        } else {
          this.routerPush(`${item.link}`);
        }
      },
      icon(img) {
        return `/static/icon/${img.replace(':', '_')}.svg`;
      },
      img(img) {
        return `/static/img/${img}.png`;
      },
      tagStyle(tags, idx) {
        let deco = {
          marginRight: '4px'
        }
        if (idx === tags.length - 1) {
          deco.marginRight = '0px';
        }
        return deco;
      },
      buttonMenuStyle(buttonMenu, idx) {
        let deco = {
          marginRight: '12px'
        }
        if (idx === buttonMenu.length - 1) {
          deco.marginRight = '0px';
        }
        return deco;
      },
      mainButtonStyle(mainButton, idx) {
        let deco = {
          marginRight: '16px'
        }
        if (idx === mainButton.length - 1) {
          deco.marginRight = '0px';
        }
        return deco;
      },
      mobilePackImgStyle(packs, idx) {
        let deco = {
          marginRight: '16px'
        }
        if (idx === packs.length - 1) {
          deco.marginRight = '0px'
        }
        return deco;
      },
      bottomMenuStyle(menu, idx) {
        let deco = {
          marginRight: '60px'
        }
        if (idx === menu.length - 1) {
          deco.marginRight = '0px'
        }
        return deco;
      },
      sectionContentPcStyle(content) {
        let deco = {
          display: 'grid',
          alignItems: 'center'
        }
        if (content.img !== '' && content.text !== '') {
          deco.gridTemplateColumns = 'repeat(2, 1fr)';
          deco.gridGap = '0px 98px';
        }
        return deco;
      },
    },
    computed: {

      slickImg() {
        if (window.innerWidth > 500) {
          let width = 318;
          let height = 483;
          let margin = '0 6px';
          return {
            width: width + 'px',
            height: height + 'px',
            margin: margin,
            borderRadius: '8px'
          }
        } else {
          let width = window.innerWidth - 24.5;
          let height = width * 1.31;
          let padding = '0 6px';
          return {
            width: width + 'px',
            height: height + 'px',
            padding: padding,
            borderRadius: '8px'
          }
        }
      },
      fixedStyle() {
        let deco = {};
        if (this.fixedState) {
          deco = {
            width: '100%',
            backgroundColor: '#fff',
            position: 'fixed',
            top: '64px',
            left: '0px',
            zIndex: 9
          }
        }
        return deco;
      },
      fixedStyleMobile() {
        let deco = {};
        if (this.mobileFixedState) {
          deco = {
            width: '100%',
            backgroundColor: '#fff',
            position: 'fixed',
            top: '60px',
            left: '0px',
            zIndex: 999
          }
        }
        return deco;
      },
      fixedBottomStyle() {
        let deco = {
          width: '100%',
          backgroundColor: '#fff',
          position: 'fixed',
          bottom: '0px',
          left: '0px',
          zIndex: 999
        }
        return deco;
      },
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  .bg-white
    background-color white

  .bg-dark
    background-color $sub
    color white

  .bg-gray3
    background-color $gray3

  .bg-primary
    background-color $primary

  .bg-primary-light2
    background-color $primary-light2

  .border-gray
    border 1px solid $gray

  .border-top-gray
    border-top 1px solid $gray

  .border-primary-light1
    border 1px solid $primary-light1

  .button-main
    border 1px solid $main
    color $main

  .section-menu-container
    border-bottom 1px solid $gray

  .bottom-menu-btn-wrap
    border-top 1px solid $gray

  .selected
    color $primary
    border-bottom 3px solid $primary


  .container
    word-break keep-all
    white-space pre-line

  .tabs
    display flex
    align-content center
    gap 10px
    margin 40px 0 60px 0
  .item-tab
    border-radius 30px
    padding 10px 24px
    background-color #ffffff
    border 1px solid $gray
    font-size 16px
    font-weight 500
  .item-tab-selected
    background-color $primary
    border 1px solid $primary
    color #ffffff

  .pc
    .section-head-grid-container
      display grid
      grid-gap 0px 120px
      align-items center

    .section-grid-list
      display grid
      grid-template-columns repeat(2, 1fr)
      grid-gap 0px 120px
      align-items center

    .section-grid-info
      display grid
      grid-template-columns repeat(2, 1fr)
      grid-gap 0px 80px

    .section-grid-info-item
      display grid
      grid-template-columns repeat(3, 1fr)
      grid-gap 12px

    .section-grid-default-pack
      display grid
      grid-template-columns repeat(2, 1fr)
      grid-gap 0px 100px
      align-items center

    .pack-info-wrapper
      display grid
      grid-template-columns repeat(2, 1fr)
      grid-gap 0px 24px
      align-items center

    .menuitem-wrapper
      display grid
      grid-template-columns repeat(2, 1fr)
      grid-gap 12px 20px

    .menuitem-wrapper-2
      display grid
      grid-template-columns repeat(3, 1fr)
      grid-gap 12px 20px

    .apps-wrapper
      display grid
      grid-template-columns repeat(3, 1fr)
      grid-gap 0px 24px

    .packs-wrapper
      display grid
      grid-template-columns repeat(3, 1fr)
      grid-gap 0px 24px

    .plugins-wrapper
      display grid
      grid-template-columns repeat(4, 1fr)
      grid-gap 16px 16px

    .split-bar
      border-top 1px solid $gray1
      width 30px
      height 0px
      transform rotate(90deg)

    .card-wrapper
      display grid
      grid-gap 24px
      grid-template-columns repeat(2, 1fr)

    .card-info
      border-radius 12px
      padding 32px
      box-shadow 0px 10px 20px rgba(0, 0, 0, 0.04)

    .quick-builder-button
      background-color $primary
      color #fff

    .prime-button
      background-color $main
      color $primary

  .mobile
    .section-grid-info-item
      display grid
      grid-template-columns repeat(2, 1fr)
      grid-gap 8px 10px

    .section-defaultpack-menu
      display grid
      grid-template-columns repeat(2, 1fr)
      grid-gap 10px

    .menuitem-wrapper
      display grid
      grid-template-columns repeat(2, 1fr)
      grid-gap 4px 4px

    .card-wrapper
      display grid
      grid-template-columns repeat(1, 1fr)
      grid-gap 12px 0

    .card-info
      border-radius 8px
      padding 20px 20px 24px
      box-shadow 0px 10px 20px rgba(0, 0, 0, 0.04)

    .drag-scroll-mobile
      white-space nowrap
      overflow-x scroll
      scroll-snap-type x mandatory
      -webkit-overflow-scrolling touch
      overflow-y hidden

    .drag-scroll-mobile::-webkit-scrollbar
      display none

    .apps-wrapper
      display grid
      grid-template-columns repeat(1, 1fr)
      grid-gap 12px 0

    .packs-wrapper
      display grid
      grid-template-columns repeat(1, 1fr)
      grid-gap 24px 0

    .plugins-wrapper
      display grid
      grid-template-columns repeat(3, 1fr)
      grid-gap 8px 8px
      word-break normal

    .menuitem-wrapper-2
      display grid
      grid-template-columns repeat(2, 1fr)
      grid-gap 10px 10px

    .bottom-menu-btn-wrap
      display grid
      grid-template-columns repeat(2, 1fr)
      grid-gap 0px 12px

    .tabs
      display flex
      flex-wrap wrap
      gap 8px
      margin 40px 0
    .item-tab
      border-radius 30px
      padding 8px 16px
      background-color #ffffff
      border 1px solid $gray
      font-size 13px
      font-weight 500
    .item-tab-selected
      background-color $primary
      border 1px solid $primary
      color #ffffff

  @media (max-width 1024px)
    .container
      padding 0px
</style>
